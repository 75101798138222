/**
 * App viewports sizes declaration
 */
export enum Breakpoint {
  'xxs' = 'xxs',
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
}
export const Breakpoints: Record<keyof typeof Breakpoint, number> = {
  xxs: 280,
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1920
} as const;
